import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface CalendarDay {
  date: Date;
  currentMonth: boolean;
}

interface CalendarMonth {
  year: number;
  month: number;
  monthName: string;
  days: CalendarDay[];
}

const DAYS_OF_WEEK_PL = ['P', 'W', 'Ś', 'C', 'P', 'S', 'N'];
const MONTHS_PL = [
  'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 
  'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'
];

// Backend API URL - adjust based on your deployment
const API_URL = process.env.REACT_APP_API_HOST || 'https://api.willatoskania.pl';

const Available = () => {
  const [bookedDates, setBookedDates] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBookedDates = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/api/calendar/booked-dates`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch booked dates');
        }
        
        const dates: string[] = await response.json();
        setBookedDates(new Set(dates));
      } catch (err) {
        console.error('Error fetching booked dates:', err);
        setError('Failed to load availability data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBookedDates();
  }, []);

  // Check if a date is booked
  const isDateBooked = (date: Date): boolean => {
    // Create a new date object at midnight UTC to avoid timezone issues
    const normalizedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dateString = normalizedDate.toISOString().split('T')[0]; // YYYY-MM-DD
    return bookedDates.has(dateString);
  };

  // Generate calendar months for the next 8 months
  const generateCalendarMonths = (): CalendarMonth[] => {
    const months = [];
    const today = new Date();
    
    for (let i = 0; i < 8; i++) {
      const currentMonth = new Date(today.getFullYear(), today.getMonth() + i, 1);
      months.push(generateMonthCalendar(currentMonth));
    }
    
    return months;
  };

  // Generate a single month calendar
  const generateMonthCalendar = (monthDate: Date): CalendarMonth => {
    const year = monthDate.getFullYear();
    const month = monthDate.getMonth();
    
    // Get first day of month and last day of month
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    
    // Get the day of the week for the first day (0 = Sunday, 1 = Monday, etc.)
    // Adjust for Monday as first day of week
    let firstDayOfWeek = firstDayOfMonth.getDay() - 1;
    if (firstDayOfWeek === -1) firstDayOfWeek = 6; // Sunday becomes 6
    
    // Get days from previous month to fill the first week
    const daysFromPrevMonth = [];
    if (firstDayOfWeek > 0) {
      const prevMonth = new Date(year, month, 0);
      const prevMonthDays = prevMonth.getDate();
      
      for (let i = prevMonthDays - firstDayOfWeek + 1; i <= prevMonthDays; i++) {
        daysFromPrevMonth.push({
          date: new Date(year, month - 1, i),
          currentMonth: false
        });
      }
    }
    
    // Get days from current month
    const daysInMonth = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysInMonth.push({
        date: new Date(year, month, i),
        currentMonth: true
      });
    }
    
    // Get days from next month to fill the last week
    const daysFromNextMonth = [];
    const totalDaysDisplayed = daysFromPrevMonth.length + daysInMonth.length;
    const remainingDays = 42 - totalDaysDisplayed; // 6 rows of 7 days
    
    for (let i = 1; i <= remainingDays; i++) {
      daysFromNextMonth.push({
        date: new Date(year, month + 1, i),
        currentMonth: false
      });
    }
    
    // Combine all days
    const allDays = [...daysFromPrevMonth, ...daysInMonth, ...daysFromNextMonth];
    
    return {
      year,
      month,
      monthName: MONTHS_PL[month],
      days: allDays
    };
  };

  // Render custom calendar view
  const renderCustomCalendar = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center w-full h-64">
          <div className="text-xl">Ładowanie kalendarza dostępności...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex justify-center items-center w-full h-64">
          <div className="text-xl text-red-600">{error}</div>
        </div>
      );
    }

    const calendarMonths = generateCalendarMonths();

    return (
      <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 w-full max-w-7xl mx-auto">
          {calendarMonths.map((monthData, index) => (
            <motion.div 
              key={`${monthData.year}-${monthData.month}`}
              className="bg-gray-100 rounded-lg shadow-md overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="bg-gray-200 p-2 text-center font-bold">
                {monthData.monthName} {monthData.year}
              </div>
              
              <div className="grid grid-cols-7 text-center text-sm">
                {DAYS_OF_WEEK_PL.map(day => (
                  <div key={day} className="p-1 font-semibold">
                    {day}
                  </div>
                ))}
                
                {monthData.days.map((day, i) => (
                  <div 
                    key={i} 
                    className={`p-1 relative ${!day.currentMonth ? 'invisible' : ''}`}
                  >
                    <div className={`
                      w-7 h-7 rounded-full flex items-center justify-center relative
                      ${isDateBooked(day.date) ? 'bg-red-100' : day.currentMonth ? 'bg-white' : 'bg-gray-100'}
                    `}>
                      <span className="z-10">{day.date.getDate()}</span>
                      {isDateBooked(day.date) && (
                        <span className="absolute inset-0 flex items-center justify-center text-red-600 font-bold z-20">
                          X
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
        
        <div className="mt-8 text-center">
          <p className="text-lg">
            Dni oznaczone <span className="text-red-600 font-bold">X</span> są już zarezerwowane.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center w-full py-8 px-4" style={{minHeight: 'calc(100vh - 5rem)'}}>
      <h1 className="text-3xl font-bold mb-8">Kalendarz dostępności</h1>
      
      {renderCustomCalendar()}
      
    </div>
  );
};

export default Available;