import { Icon } from '@iconify/react';
import { icons, months } from '../content/calendar';
import { useRef, useState } from 'react';
import { Navigator } from '../components/navigator';
import AText from '../components/animatedText';

const Calendar = () => {
    const refs = useRef<(HTMLDivElement | null)[]>([]);
    
    const scrollTo = (index: number) => {
        console.log(refs.current[index]);
        refs.current[index]?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
    return (
        
        <div className='flex flex-row' style={{
            height: 'calc(100vh - 5rem)',
            background: 'rgb(240, 234, 220)'
        }}>
          <div className='flex flex-col justify-evenly h-full side-bar'>
              {months.map(({name}, index) => 
                    <AText 
                        className="text-3xl w-full px-20 text-left cursor-pointer" 
                        onClick={() => scrollTo(index)}
                        d={(2+index)/10}
                    >
                      {name}
                    </AText>
              )}
          </div>
            <div className="calendar-content flex-1 flex flex-col gap-10 pb-6 px-20" style={{flex: 3}}>
                <div className='w-full flex justify-center'>
                    <img src='https://www.climate.top/graphs/florence-climate-graph.png'/>
                </div>
                {months.map((month, index) => 
                    <div ref={x => refs.current[index] = x} className='flex flex-col'>
                        <span className='text-2xl mb-3'>{month.name}</span>
                        <div className='flex flex-row gap-2'>
                        {month.tags.map(tag => 
                            <Icon icon={icons[tag]} width={'2rem'} height={'2rem'}/>
                        )}
                        </div>
                        {Boolean(month.text?.length) && <div className='flex flex-col gap-3 mt-4'>
                        {month.text?.map(text => 
                            <p style={{whiteSpace: 'pre-line'}}>{text}</p>
                        )}
                        </div>}
                    </div>
                )}
            </div>
            {/* <div className='side-bar'/> */}
        </div>
    );
}

export default Calendar;