import { useState } from "react";
import { Navigator } from "./navigator";

const Header = () => {
      const [navigatorOpen, setNavigatorOpen] = useState(false);

      const clickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setNavigatorOpen(x => !x);
      }

      return (
            <>
            <div className="top-0 sticky bg-no w-full flex items-center justify-between z-30 dynamic-padding-x">
                  <button className="h-20 py-6 header-button" onClick={clickButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                  </button>
                  <span className="text-3xl text-center">
                        Willa Toskania
                  </span>
                  <button className="h-20 py-6" onClick={() => setNavigatorOpen(x => !x)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 24" fill="none" stroke="transparent" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                  </button>
            </div>
            <Navigator open={navigatorOpen} close={()=>setNavigatorOpen(false)}/>
            </>
      );
};

export default Header;