import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AText from "./animatedText";

export const Navigator = ({open, close} : {open: boolean, close: ()=>void}) => {
      const navigate = useNavigate();
  
      const options = useMemo(() => [
          ['Strona główna', '/'],
          ['Region', '/location'],
          ['Sypialnie', '/bedrooms'],
          ['Kalendarium', '/calendar'],
          ['Dostępność', '/availability'],
          ['Kontakt', '/contact'],
      ], []);
  
      const selectOption = (path: string) => {
          navigate(path);
          close();
      }

      const clickInside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
      }

      useEffect(() => {
        document.addEventListener('click', close);
        return () => document.removeEventListener('click', close);
      }, []);
  
      return (open ?
          <div className='bg-pr h-screen fixed left-0 top-6 py-20 flex flex-col gap-8 z-20 bg-sl' onClick={clickInside}>
              {options.map(([name, path], index) => 
                    <AText 
                        onClick={() => selectOption(path)} 
                        className="text-3xl w-full px-20 flex center cursor-pointer"
                        d={(2+index)/10}
                    >
                      {name}
                    </AText>
              )}
          </div> : <></>
      )
  }